import React from 'react';

const Login = React.lazy(() => import('./App/pages/auth/Login'));
const SetPassword = React.lazy(() => import('./App/pages/auth/SetPassword'));
const ForgotPassword = React.lazy(() => import('./App/pages/auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./App/pages/auth/ResetPassword'));
const LoginInvalid = React.lazy(() => import('./App/pages/auth/LoginInvalid'));
const Invalid = React.lazy(() => import('./App/pages/auth/Invalid'));

const route = [
    { path: '/login', exact: true, name: 'ログイン', component: Login },
    { path: '/set_password', exact: true, name: 'パスワード設定', component: SetPassword },
    { path: '/forgot_password', exact: true, name: 'パスワード再発行', component: ForgotPassword },
    { path: '/reset_password', exact: true, name: 'パスワード再設定', component: ResetPassword },
    { path: '/login_invalid', exact: true, name: 'ログイン無効ペジー', component: LoginInvalid },
    { path: '/invalid', exact: true, name: '無効ペジー', component: Invalid },
];

export default route;