import { fetchElectricMaster } from "../actions";
import apiCaller from "../../utils/api";

function fetchElectric() {
    return dispatch => apiCaller.getRequest("electricMaster/list", {},
        (response) => {
            dispatch(fetchElectricMaster(response.data.results.data));
        },
        (error) => {
            dispatch(fetchElectricMaster({}));
            return {};
        }
    );
}

export default fetchElectric;
