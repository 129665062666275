import React, { Component } from "react";

class ScrollTopButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			intervalId: 0,
			isVisible: false,
		};
	};

	componentDidMount() {
		var scrollComponent = this;
		document.addEventListener("scroll", function(e) {
			scrollComponent.toggleVisibility();
		});
	};

	toggleVisibility() {
		if (window.pageYOffset > 100) {
			this.setState({
				isVisible: true
			});
		} else {
			this.setState({
				isVisible: false
			});
		}
	};

	scrollStep() {
		if (window.pageYOffset === 0) {
			clearInterval(this.state.intervalId);
		}
		window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
	};

	scrollToTop() {
		let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
		this.setState({ intervalId: intervalId });
	};

	render() {
		const { isVisible } = this.state;

		return (
			<React.Fragment>
				{isVisible && 
					<button
						title="トップに戻る"
						className="scroll"
						onClick={() => {
							this.scrollToTop();
						}}
					>
						<i className="feather icon-arrow-up"/>
					</button>
				}
			</React.Fragment>
		);
	};
}

export default ScrollTopButton;
