import React, { Component, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Loadable from "react-loadable";
import { AuthContext } from "../context/auth";
import Aux from "../hoc/_Aux";
import routes from "../route";
import apiCaller from "../utils/api";
import Loader from "./components/loader/Loader";
import ScrollToTop from "./components/scroll/ScrollToTop";
import ScrollTopButton from "./components/scrollbutton/ScrollTopButton";
import PrivateRoute from "./components/PrivateRoute";
import fetchKubunAction from "../store/fetch/fetchKubun";
import fetchElectricAction from "../store/fetch/fetchElectric";
import { isEmpty } from "lodash";

const Layout = Loadable({
	loader: () => import("./components/layout/Layout"),
	loading: Loader,
});

class App extends Component {
	constructor(props) {
		super(props);
		const authToken = localStorage.getItem("authToken");
		this.state = {
			authToken,
			pages: ["/login", "/forgot_password", "/reset_password", "/set_password", "/login_invalid", "/invalid"],
		}
	};

	componentDidMount = () => {
		const { 
			history,
			location,
			kubun,
			fetchKubun,
			electric,
			fetchElectric,
		} = this.props;
		
		apiCaller.getRequest("valid", {},
			(response) => {
				if (location.pathname === "/invalid") {
					// If current url path name is invalid then go back to previous page
					history.goBack();
				}
			},
			(error) => {
				if (error.response?.status) {
					history.push('/invalid');
				} 
			}
		);

		if (isEmpty(kubun)) {
			fetchKubun();
		}

		if (isEmpty(electric)) {
			fetchElectric();
		}
	};

	setAuthToken = token => {
		if (token) {
			localStorage.setItem("authToken", token);
		} else {
			localStorage.removeItem("authToken");
		}

		this.setState({
			authToken: token,
		})
	};

	render() {
		const {
			history,
			location,
		} = this.props;
		
		const user_id = localStorage.getItem("user_id");
		if (!user_id && !this.state.pages.includes(location.pathname)) history.push('/login');

		const menu = routes.map((route, index) => {
			return route.component ? (
				<Route
					key={index}
					path={route.path}
					exact={route.exact}
					name={route.name}
					render={(props) => <route.component {...props} />}
				/>
			) : null;
		});

		return (
			<Aux>
				<AuthContext.Provider value={{ authToken: this.state.authToken, setAuthToken: this.setAuthToken }}>
					<ScrollToTop>
						<Suspense fallback={<Loader />}>
							<Switch>
								{menu}
								<Route exact path="/">
									<Redirect to="/customer" />
								</Route>
								<PrivateRoute path="/" component={Layout} />
							</Switch>
						</Suspense>
					</ScrollToTop>
					<ScrollTopButton scrollStepInPx="50" delayInMs="16.66" />
				</AuthContext.Provider>
			</Aux>
		);
	}
}
  
const mapDispatchToProps = (dispatch) =>
	bindActionCreators({
		fetchKubun: fetchKubunAction,
		fetchElectric: fetchElectricAction,
	},
	dispatch
);
  
export default withRouter(connect(null, mapDispatchToProps)(App));
