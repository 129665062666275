import { fetchKubunMaster } from "../actions";
import apiCaller from "../../utils/api";

function fetchKubun() {
    return dispatch => apiCaller.getRequest("kubunMaster/list", {},
        (response) => {
            dispatch(fetchKubunMaster(response.data.results.data));
        },
        (error) => {
            dispatch(fetchKubunMaster({}));
            return {};
        }
    );
}
export default fetchKubun;
