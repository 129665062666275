export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const LOGIN = 'LOGIN';
export const FETCH_KUBUN_MASTER = 'FETCH_KUBUN_MASTER';
export const FETCH_ELECTRIC_MASTER = 'FETCH_ELECTRIC_MASTER';
export const FETCH_DAIRITEN = 'FETCH_DAIRITEN';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const UPDATE_DAIRITEN = 'UPDATE_DAIRITEN';

export function fetchKubunMaster(kubun) {
    return {
        type: FETCH_KUBUN_MASTER,
        kubun: kubun
    }
}

export function fetchElectricMaster(electric) {
    return {
        type: FETCH_ELECTRIC_MASTER,
        electric: electric
    }
}

export function fetchDairitenUser(dairiten) {
    return {
        type: FETCH_DAIRITEN,
        dairiten: dairiten
    }
}

export function updateDairiten(dairiten) {
    return {
        type: UPDATE_DAIRITEN,
        dairiten: dairiten
    }
}
