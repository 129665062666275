import * as actionTypes from "./actions";
import config from "./../config";

const initialState = {
	isOpen: [], // For active default menu
	isTrigger: [], // For active default menu, set blank for horizontal
	...config,
	isFullScreen: false, // Static can't change
	kubun: {}, // Kubun master
  	electric: {}, // Electric master
	dairiten: {}, // Dairiten user data
};

const reducer = (state = initialState, action) => {
	let trigger = [];
	let open = [];

	switch (action.type) {
		case actionTypes.COLLAPSE_MENU:
			return {
				...state,
				collapseMenu: !state.collapseMenu,
			};
		case actionTypes.COLLAPSE_TOGGLE:
			if (action.menu.type === "sub") {
				open = state.isOpen;
				trigger = state.isTrigger;

				const triggerIndex = trigger.indexOf(action.menu.id);
				if (triggerIndex > -1) {
					open = open.filter((item) => item !== action.menu.id);
					trigger = trigger.filter((item) => item !== action.menu.id);
				}

				if (triggerIndex === -1) {
					open = [...open, action.menu.id];
					trigger = [...trigger, action.menu.id];
				}
			} else {
				open = state.isOpen;
				const triggerIndex = state.isTrigger.indexOf(action.menu.id);
				trigger = triggerIndex === -1 ? [action.menu.id] : [];
				open = triggerIndex === -1 ? [action.menu.id] : [];
			}
			return {
				...state,
				isOpen: open,
				isTrigger: trigger,
			};
		case actionTypes.NAV_CONTENT_LEAVE:
			return {
				...state,
				isOpen: open,
				isTrigger: trigger,
			};
		case actionTypes.NAV_COLLAPSE_LEAVE:
			if (action.menu.type === "sub") {
				open = state.isOpen;
				trigger = state.isTrigger;

				const triggerIndex = trigger.indexOf(action.menu.id);
				if (triggerIndex > -1) {
					open = open.filter((item) => item !== action.menu.id);
					trigger = trigger.filter((item) => item !== action.menu.id);
				}
				return {
					...state,
					isOpen: open,
					isTrigger: trigger,
				};
			}
			return { ...state };
		case actionTypes.FULL_SCREEN:
			return {
				...state,
				isFullScreen: !state.isFullScreen,
			};
		case actionTypes.FULL_SCREEN_EXIT:
			return {
				...state,
				isFullScreen: false,
			};
		case actionTypes.CHANGE_LAYOUT:
			return {
				...state,
				layout: action.layout,
			};
		case actionTypes.SET_PAGE_TITLE:
			return {
				...state,
				routeProps: action.routeProps,
			};
		case actionTypes.FETCH_KUBUN_MASTER:
			return {
				...state,
				kubun: action.kubun,
			};
		case actionTypes.FETCH_ELECTRIC_MASTER:
			return {
				...state,
				electric: action.electric,
			};
		case actionTypes.FETCH_DAIRITEN:
			return {
				...state,
				dairiten: action.dairiten,
			};
		case actionTypes.UPDATE_DAIRITEN:
			return {
				...state,
				dairiten: action.dairiten,
			};
		default:
			return state;
	}
};

export default reducer;
